@media(min-width:220px) {
    .preview-frame{
        min-width: 160px !important;
        max-width: 40vh !important;
        min-height: 160px !important;
        max-height: 40vh !important;
    }
    .setup{
        margin-bottom: -1.5rem !important;
    }
    .controls-frame{
        max-width: 200px !important;
    }
    .name-input{
        max-width: 340px !important;
    }
}
@media(min-width:480px) {
    
}
@media(min-width:768px) {
    
}
@media(min-width:1440px) {
    .preview-frame{
        min-width: 350px !important;
        max-width: 50vh !important;
        min-height: 180px !important;
        max-height: 50vh !important;
    }
    .controls-frame{
        max-width: 50vh !important;
    }
}
@media(min-width:2560px) {
    
}












/* @media(min-width:320px) {
    
}
@media(min-width:480px) {
    
}
@media(min-width:768px) {
    
}
@media(min-width:992px) {
    
}
@media(min-width:1200px) {
    
} */